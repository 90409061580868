export const songSection = {
  title: "Your Favorite Songs",
  description:
    "Find the songs that have been on repeat for you. Are you a trailblazer?",
  buttonText: "Go to Songs",
  altButtonText: "Login To See Your Songs",
  imageUrl:
    "https://images.unsplash.com/photo-1669937401545-c10155e96124?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  imageAlt: "Man listening to music on headphones",
};

export const artistSection = {
  title: "Your Favorite Artists",
  description:
    "These artists and bands have dominated your airwaves lately. Who is on the top of your charts?",
  buttonText: "Go To Artists",
  altButtonText: "Login To See Your Artists",
  imageUrl:
    "https://images.unsplash.com/photo-1606614520047-8ad516d9d84b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  imageAlt: "musician singing and playing guitar",
};

export const spotifyProfile = {
  display_name: "Erik Aakre",
  external_urls: {
    spotify: "https://open.spotify.com/user/erikaakre",
  },
  followers: {
    href: null,
    total: 2,
  },
  href: "https://api.spotify.com/v1/users/erikaakre",
  id: "erikaakre",
  images: [
    {
      url: "https://i.scdn.co/image/ab67757000003b822944eef6f1eb32076575cb0d",
      height: 64,
      width: 64,
    },
    {
      url: "https://i.scdn.co/image/ab6775700000ee852944eef6f1eb32076575cb0d",
      height: 300,
      width: 300,
    },
  ],
  type: "user",
  uri: "spotify:user:erikaakre",
};

export const spotifyArtists = {
  href: "https://api.spotify.com/v1/me/top/artists?offset=0&limit=10&time_range=medium_term&locale=en-US%2Cen%3Bq%3D0.9",
  limit: 10,
  next: "https://api.spotify.com/v1/me/top/artists?offset=10&limit=10&time_range=medium_term&locale=en-US%2Cen%3Bq%3D0.9",
  offset: 0,
  previous: null,
  total: 205,
  items: [
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/6W2lyFO79SNpk3ZpF0A2s9",
      },
      followers: {
        href: null,
        total: 14624,
      },
      genres: ["christian pop", "christian trap"],
      href: "https://api.spotify.com/v1/artists/6W2lyFO79SNpk3ZpF0A2s9",
      id: "6W2lyFO79SNpk3ZpF0A2s9",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5ebc8a9d3a3825abf714ede3ab4",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab67616100005174c8a9d3a3825abf714ede3ab4",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f178c8a9d3a3825abf714ede3ab4",
          height: 160,
          width: 160,
        },
      ],
      name: "Jet Trouble",
      popularity: 46,
      type: "artist",
      uri: "spotify:artist:6W2lyFO79SNpk3ZpF0A2s9",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/2gXmjQIxCO8lMnSncluZaU",
      },
      followers: {
        href: null,
        total: 577624,
      },
      genres: ["ccm", "deep ccm", "worship"],
      href: "https://api.spotify.com/v1/artists/2gXmjQIxCO8lMnSncluZaU",
      id: "2gXmjQIxCO8lMnSncluZaU",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb5947965769c01214315fb28f",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051745947965769c01214315fb28f",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1785947965769c01214315fb28f",
          height: 160,
          width: 160,
        },
      ],
      name: "Cory Asbury",
      popularity: 63,
      type: "artist",
      uri: "spotify:artist:2gXmjQIxCO8lMnSncluZaU",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/26DytDdxKgr9N0tdrBSLs2",
      },
      followers: {
        href: null,
        total: 72208,
      },
      genres: ["acoustic pop"],
      href: "https://api.spotify.com/v1/artists/26DytDdxKgr9N0tdrBSLs2",
      id: "26DytDdxKgr9N0tdrBSLs2",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb6c92bc5943e540fc756dcc90",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051746c92bc5943e540fc756dcc90",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1786c92bc5943e540fc756dcc90",
          height: 160,
          width: 160,
        },
      ],
      name: "Wilder Woods",
      popularity: 47,
      type: "artist",
      uri: "spotify:artist:26DytDdxKgr9N0tdrBSLs2",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/1jGACwVpRJvsOfg29pM5L7",
      },
      followers: {
        href: null,
        total: 16692,
      },
      genres: [],
      href: "https://api.spotify.com/v1/artists/1jGACwVpRJvsOfg29pM5L7",
      id: "1jGACwVpRJvsOfg29pM5L7",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb1759df610286ed6d2f1f1c6f",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051741759df610286ed6d2f1f1c6f",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1781759df610286ed6d2f1f1c6f",
          height: 160,
          width: 160,
        },
      ],
      name: "FLOYA",
      popularity: 38,
      type: "artist",
      uri: "spotify:artist:1jGACwVpRJvsOfg29pM5L7",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/2NQEwAVHBNcI0tGMLlWwF1",
      },
      followers: {
        href: null,
        total: 475249,
      },
      genres: ["acoustic pop", "neo mellow"],
      href: "https://api.spotify.com/v1/artists/2NQEwAVHBNcI0tGMLlWwF1",
      id: "2NQEwAVHBNcI0tGMLlWwF1",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb7e98747e08d1ed8dd90f1f52",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051747e98747e08d1ed8dd90f1f52",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1787e98747e08d1ed8dd90f1f52",
          height: 160,
          width: 160,
        },
      ],
      name: "Mat Kearney",
      popularity: 53,
      type: "artist",
      uri: "spotify:artist:2NQEwAVHBNcI0tGMLlWwF1",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/6eUKZXaKkcviH0Ku9w2n3V",
      },
      followers: {
        href: null,
        total: 113529895,
      },
      genres: ["pop", "singer-songwriter pop", "uk pop"],
      href: "https://api.spotify.com/v1/artists/6eUKZXaKkcviH0Ku9w2n3V",
      id: "6eUKZXaKkcviH0Ku9w2n3V",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb3bcef85e105dfc42399ef0ba",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051743bcef85e105dfc42399ef0ba",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1783bcef85e105dfc42399ef0ba",
          height: 160,
          width: 160,
        },
      ],
      name: "Ed Sheeran",
      popularity: 87,
      type: "artist",
      uri: "spotify:artist:6eUKZXaKkcviH0Ku9w2n3V",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/6S58b0fr8TkWrEHOH4tRVu",
      },
      followers: {
        href: null,
        total: 679769,
      },
      genres: ["ccm", "christian alternative rock", "christian music"],
      href: "https://api.spotify.com/v1/artists/6S58b0fr8TkWrEHOH4tRVu",
      id: "6S58b0fr8TkWrEHOH4tRVu",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb1182ae93b58a17bc688564fb",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051741182ae93b58a17bc688564fb",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1781182ae93b58a17bc688564fb",
          height: 160,
          width: 160,
        },
      ],
      name: "Switchfoot",
      popularity: 59,
      type: "artist",
      uri: "spotify:artist:6S58b0fr8TkWrEHOH4tRVu",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/0SfsnGyD8FpIN4U4WCkBZ5",
      },
      followers: {
        href: null,
        total: 4256140,
      },
      genres: [
        "dutch trance",
        "edm",
        "pop dance",
        "progressive house",
        "trance",
      ],
      href: "https://api.spotify.com/v1/artists/0SfsnGyD8FpIN4U4WCkBZ5",
      id: "0SfsnGyD8FpIN4U4WCkBZ5",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb5328c468cef8019bc41b63a8",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051745328c468cef8019bc41b63a8",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1785328c468cef8019bc41b63a8",
          height: 160,
          width: 160,
        },
      ],
      name: "Armin van Buuren",
      popularity: 75,
      type: "artist",
      uri: "spotify:artist:0SfsnGyD8FpIN4U4WCkBZ5",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/3nJWBJvK7uGvfp4iZh9CkN",
      },
      followers: {
        href: null,
        total: 359690,
      },
      genres: [
        "canadian ccm",
        "christian alternative rock",
        "christian punk",
        "pop punk",
      ],
      href: "https://api.spotify.com/v1/artists/3nJWBJvK7uGvfp4iZh9CkN",
      id: "3nJWBJvK7uGvfp4iZh9CkN",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb1e0743a3e000215916f70238",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051741e0743a3e000215916f70238",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1781e0743a3e000215916f70238",
          height: 160,
          width: 160,
        },
      ],
      name: "Relient K",
      popularity: 52,
      type: "artist",
      uri: "spotify:artist:3nJWBJvK7uGvfp4iZh9CkN",
    },
    {
      external_urls: {
        spotify: "https://open.spotify.com/artist/610EjgFatGvVPtib97jQ8G",
      },
      followers: {
        href: null,
        total: 816497,
      },
      genres: [
        "ccm",
        "christian alternative rock",
        "christian indie",
        "christian music",
        "folk-pop",
      ],
      href: "https://api.spotify.com/v1/artists/610EjgFatGvVPtib97jQ8G",
      id: "610EjgFatGvVPtib97jQ8G",
      images: [
        {
          url: "https://i.scdn.co/image/ab6761610000e5eb8cb146d311685b6a1704d6ea",
          height: 640,
          width: 640,
        },
        {
          url: "https://i.scdn.co/image/ab676161000051748cb146d311685b6a1704d6ea",
          height: 320,
          width: 320,
        },
        {
          url: "https://i.scdn.co/image/ab6761610000f1788cb146d311685b6a1704d6ea",
          height: 160,
          width: 160,
        },
      ],
      name: "NEEDTOBREATHE",
      popularity: 63,
      type: "artist",
      uri: "spotify:artist:610EjgFatGvVPtib97jQ8G",
    },
  ],
};

export const spotifyTracks = {
  href: "https://api.spotify.com/v1/me/top/tracks?offset=0&limit=10&time_range=medium_term&locale=en-US%2Cen%3Bq%3D0.9",
  limit: 10,
  next: "https://api.spotify.com/v1/me/top/tracks?offset=10&limit=10&time_range=medium_term&locale=en-US%2Cen%3Bq%3D0.9",
  offset: 0,
  previous: null,
  total: 3063,
  items: [
    {
      album: {
        album_type: "SINGLE",
        total_tracks: 2,
        available_markets: [],
        external_urls: {
          spotify: "https://open.spotify.com/album/15O4kOXtOho4YAnWuoFOwC",
        },
        href: "https://api.spotify.com/v1/albums/15O4kOXtOho4YAnWuoFOwC",
        id: "15O4kOXtOho4YAnWuoFOwC",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b273d9a070121ae9406610cd857c",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e02d9a070121ae9406610cd857c",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00004851d9a070121ae9406610cd857c",
            height: 64,
            width: 64,
          },
        ],
        name: "Jesus Speak (Help My Unbelief)",
        release_date: "2023-08-25",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:15O4kOXtOho4YAnWuoFOwC",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/7H9pyCCQxjzJb9aaIsrSgp",
            },
            href: "https://api.spotify.com/v1/artists/7H9pyCCQxjzJb9aaIsrSgp",
            id: "7H9pyCCQxjzJb9aaIsrSgp",
            name: "Chidiya Ohiagu",
            type: "artist",
            uri: "spotify:artist:7H9pyCCQxjzJb9aaIsrSgp",
          },
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/1qkNJ1IgevMh0GG9rMEh8F",
            },
            href: "https://api.spotify.com/v1/artists/1qkNJ1IgevMh0GG9rMEh8F",
            id: "1qkNJ1IgevMh0GG9rMEh8F",
            name: "Xander Sallows",
            type: "artist",
            uri: "spotify:artist:1qkNJ1IgevMh0GG9rMEh8F",
          },
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/6ULNzmxY00PU4YfT3ZZp7T",
            },
            href: "https://api.spotify.com/v1/artists/6ULNzmxY00PU4YfT3ZZp7T",
            id: "6ULNzmxY00PU4YfT3ZZp7T",
            name: "Joshua's Giants",
            type: "artist",
            uri: "spotify:artist:6ULNzmxY00PU4YfT3ZZp7T",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/7H9pyCCQxjzJb9aaIsrSgp",
          },
          href: "https://api.spotify.com/v1/artists/7H9pyCCQxjzJb9aaIsrSgp",
          id: "7H9pyCCQxjzJb9aaIsrSgp",
          name: "Chidiya Ohiagu",
          type: "artist",
          uri: "spotify:artist:7H9pyCCQxjzJb9aaIsrSgp",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1qkNJ1IgevMh0GG9rMEh8F",
          },
          href: "https://api.spotify.com/v1/artists/1qkNJ1IgevMh0GG9rMEh8F",
          id: "1qkNJ1IgevMh0GG9rMEh8F",
          name: "Xander Sallows",
          type: "artist",
          uri: "spotify:artist:1qkNJ1IgevMh0GG9rMEh8F",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/6ULNzmxY00PU4YfT3ZZp7T",
          },
          href: "https://api.spotify.com/v1/artists/6ULNzmxY00PU4YfT3ZZp7T",
          id: "6ULNzmxY00PU4YfT3ZZp7T",
          name: "Joshua's Giants",
          type: "artist",
          uri: "spotify:artist:6ULNzmxY00PU4YfT3ZZp7T",
        },
      ],
      available_markets: [],
      disc_number: 1,
      duration_ms: 203368,
      explicit: false,
      external_ids: {
        isrc: "AUMEV2382011",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/5NLQBgFphfAuzfKu4MUC7W",
      },
      href: "https://api.spotify.com/v1/tracks/5NLQBgFphfAuzfKu4MUC7W",
      id: "5NLQBgFphfAuzfKu4MUC7W",
      name: "Jesus Speak (Help My Unbelief)",
      popularity: 22,
      preview_url:
        "https://p.scdn.co/mp3-preview/30c8882c29e149de96a31c94df16b1a8327f21ad?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 1,
      type: "track",
      uri: "spotify:track:5NLQBgFphfAuzfKu4MUC7W",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 12,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "KZ",
          "MD",
          "UA",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "DJ",
          "ZM",
          "CG",
          "TJ",
          "VE",
          "ET",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/6qrk23zUlbVaZZVrqBDt4M",
        },
        href: "https://api.spotify.com/v1/albums/6qrk23zUlbVaZZVrqBDt4M",
        id: "6qrk23zUlbVaZZVrqBDt4M",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b2735502744f14fdefc4236284a4",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e025502744f14fdefc4236284a4",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d000048515502744f14fdefc4236284a4",
            height: 64,
            width: 64,
          },
        ],
        name: "Falling Up",
        release_date: "2015-11-13",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:6qrk23zUlbVaZZVrqBDt4M",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/0qJxsH9kWhslWau4Ork4Zm",
            },
            href: "https://api.spotify.com/v1/artists/0qJxsH9kWhslWau4Ork4Zm",
            id: "0qJxsH9kWhslWau4Ork4Zm",
            name: "Falling Up",
            type: "artist",
            uri: "spotify:artist:0qJxsH9kWhslWau4Ork4Zm",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/0qJxsH9kWhslWau4Ork4Zm",
          },
          href: "https://api.spotify.com/v1/artists/0qJxsH9kWhslWau4Ork4Zm",
          id: "0qJxsH9kWhslWau4Ork4Zm",
          name: "Falling Up",
          type: "artist",
          uri: "spotify:artist:0qJxsH9kWhslWau4Ork4Zm",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "KZ",
        "MD",
        "UA",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "DJ",
        "ZM",
        "CG",
        "TJ",
        "VE",
        "ET",
      ],
      disc_number: 1,
      duration_ms: 288520,
      explicit: false,
      external_ids: {
        isrc: "TCACJ1586708",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/4hshwUXYOtzGDmgi6vIsT1",
      },
      href: "https://api.spotify.com/v1/tracks/4hshwUXYOtzGDmgi6vIsT1",
      id: "4hshwUXYOtzGDmgi6vIsT1",
      name: "Hydro",
      popularity: 16,
      preview_url:
        "https://p.scdn.co/mp3-preview/cece1e87012eb3d5ab3712f8e4ec59254b15ca17?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 4,
      type: "track",
      uri: "spotify:track:4hshwUXYOtzGDmgi6vIsT1",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 12,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "KZ",
          "MD",
          "UA",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "DJ",
          "ZM",
          "CG",
          "TJ",
          "VE",
          "ET",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/6qrk23zUlbVaZZVrqBDt4M",
        },
        href: "https://api.spotify.com/v1/albums/6qrk23zUlbVaZZVrqBDt4M",
        id: "6qrk23zUlbVaZZVrqBDt4M",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b2735502744f14fdefc4236284a4",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e025502744f14fdefc4236284a4",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d000048515502744f14fdefc4236284a4",
            height: 64,
            width: 64,
          },
        ],
        name: "Falling Up",
        release_date: "2015-11-13",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:6qrk23zUlbVaZZVrqBDt4M",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/0qJxsH9kWhslWau4Ork4Zm",
            },
            href: "https://api.spotify.com/v1/artists/0qJxsH9kWhslWau4Ork4Zm",
            id: "0qJxsH9kWhslWau4Ork4Zm",
            name: "Falling Up",
            type: "artist",
            uri: "spotify:artist:0qJxsH9kWhslWau4Ork4Zm",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/0qJxsH9kWhslWau4Ork4Zm",
          },
          href: "https://api.spotify.com/v1/artists/0qJxsH9kWhslWau4Ork4Zm",
          id: "0qJxsH9kWhslWau4Ork4Zm",
          name: "Falling Up",
          type: "artist",
          uri: "spotify:artist:0qJxsH9kWhslWau4Ork4Zm",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "KZ",
        "MD",
        "UA",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "DJ",
        "ZM",
        "CG",
        "TJ",
        "VE",
        "ET",
      ],
      disc_number: 1,
      duration_ms: 182066,
      explicit: false,
      external_ids: {
        isrc: "TCACJ1586712",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/7nhI3kqnO4XYMKruPZfgBq",
      },
      href: "https://api.spotify.com/v1/tracks/7nhI3kqnO4XYMKruPZfgBq",
      id: "7nhI3kqnO4XYMKruPZfgBq",
      name: "In the Woodshop",
      popularity: 27,
      preview_url:
        "https://p.scdn.co/mp3-preview/f3245cb29f435f51f965900507429480c006124f?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 8,
      type: "track",
      uri: "spotify:track:7nhI3kqnO4XYMKruPZfgBq",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 10,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/6AARjaQ2hd1WfU3S0JfImo",
        },
        href: "https://api.spotify.com/v1/albums/6AARjaQ2hd1WfU3S0JfImo",
        id: "6AARjaQ2hd1WfU3S0JfImo",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b2735cafa7e4e25286d303ff90e3",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e025cafa7e4e25286d303ff90e3",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d000048515cafa7e4e25286d303ff90e3",
            height: 64,
            width: 64,
          },
        ],
        name: "Though None Go",
        release_date: "2022-08-05",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:6AARjaQ2hd1WfU3S0JfImo",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/6W2lyFO79SNpk3ZpF0A2s9",
            },
            href: "https://api.spotify.com/v1/artists/6W2lyFO79SNpk3ZpF0A2s9",
            id: "6W2lyFO79SNpk3ZpF0A2s9",
            name: "Jet Trouble",
            type: "artist",
            uri: "spotify:artist:6W2lyFO79SNpk3ZpF0A2s9",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/6W2lyFO79SNpk3ZpF0A2s9",
          },
          href: "https://api.spotify.com/v1/artists/6W2lyFO79SNpk3ZpF0A2s9",
          id: "6W2lyFO79SNpk3ZpF0A2s9",
          name: "Jet Trouble",
          type: "artist",
          uri: "spotify:artist:6W2lyFO79SNpk3ZpF0A2s9",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 138919,
      explicit: false,
      external_ids: {
        isrc: "QZES92216683",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/5M4QYhLJF1lCmF2bflR8Wq",
      },
      href: "https://api.spotify.com/v1/tracks/5M4QYhLJF1lCmF2bflR8Wq",
      id: "5M4QYhLJF1lCmF2bflR8Wq",
      name: "Where To Find Me",
      popularity: 56,
      preview_url:
        "https://p.scdn.co/mp3-preview/c48a779564be13a35a02f2d229b5dd6625515cb7?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 5,
      type: "track",
      uri: "spotify:track:5M4QYhLJF1lCmF2bflR8Wq",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 10,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/6AARjaQ2hd1WfU3S0JfImo",
        },
        href: "https://api.spotify.com/v1/albums/6AARjaQ2hd1WfU3S0JfImo",
        id: "6AARjaQ2hd1WfU3S0JfImo",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b2735cafa7e4e25286d303ff90e3",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e025cafa7e4e25286d303ff90e3",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d000048515cafa7e4e25286d303ff90e3",
            height: 64,
            width: 64,
          },
        ],
        name: "Though None Go",
        release_date: "2022-08-05",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:6AARjaQ2hd1WfU3S0JfImo",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/6W2lyFO79SNpk3ZpF0A2s9",
            },
            href: "https://api.spotify.com/v1/artists/6W2lyFO79SNpk3ZpF0A2s9",
            id: "6W2lyFO79SNpk3ZpF0A2s9",
            name: "Jet Trouble",
            type: "artist",
            uri: "spotify:artist:6W2lyFO79SNpk3ZpF0A2s9",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/6W2lyFO79SNpk3ZpF0A2s9",
          },
          href: "https://api.spotify.com/v1/artists/6W2lyFO79SNpk3ZpF0A2s9",
          id: "6W2lyFO79SNpk3ZpF0A2s9",
          name: "Jet Trouble",
          type: "artist",
          uri: "spotify:artist:6W2lyFO79SNpk3ZpF0A2s9",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 77975,
      explicit: false,
      external_ids: {
        isrc: "QZMEM2294307",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/3zrOUPDVI1nvhER2bYEKiL",
      },
      href: "https://api.spotify.com/v1/tracks/3zrOUPDVI1nvhER2bYEKiL",
      id: "3zrOUPDVI1nvhER2bYEKiL",
      name: "Though None Go",
      popularity: 53,
      preview_url:
        "https://p.scdn.co/mp3-preview/488a24dcabfa604138408d435125c2ab1e8092ed?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 1,
      type: "track",
      uri: "spotify:track:3zrOUPDVI1nvhER2bYEKiL",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 11,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/1FmWxK03MqG0REpDWHiRg4",
        },
        href: "https://api.spotify.com/v1/albums/1FmWxK03MqG0REpDWHiRg4",
        id: "1FmWxK03MqG0REpDWHiRg4",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b27379cfee3c465540b0bca7b5ed",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e0279cfee3c465540b0bca7b5ed",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d0000485179cfee3c465540b0bca7b5ed",
            height: 64,
            width: 64,
          },
        ],
        name: "Pioneer",
        release_date: "2023-09-15",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:1FmWxK03MqG0REpDWHiRg4",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/2gXmjQIxCO8lMnSncluZaU",
            },
            href: "https://api.spotify.com/v1/artists/2gXmjQIxCO8lMnSncluZaU",
            id: "2gXmjQIxCO8lMnSncluZaU",
            name: "Cory Asbury",
            type: "artist",
            uri: "spotify:artist:2gXmjQIxCO8lMnSncluZaU",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/2gXmjQIxCO8lMnSncluZaU",
          },
          href: "https://api.spotify.com/v1/artists/2gXmjQIxCO8lMnSncluZaU",
          id: "2gXmjQIxCO8lMnSncluZaU",
          name: "Cory Asbury",
          type: "artist",
          uri: "spotify:artist:2gXmjQIxCO8lMnSncluZaU",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 211904,
      explicit: false,
      external_ids: {
        isrc: "QMGVJ2300101",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/0VRJVOMvyR4gubVIoGkS5C",
      },
      href: "https://api.spotify.com/v1/tracks/0VRJVOMvyR4gubVIoGkS5C",
      id: "0VRJVOMvyR4gubVIoGkS5C",
      name: "The Promise is the Same",
      popularity: 40,
      preview_url:
        "https://p.scdn.co/mp3-preview/d007e3585ecbdc002cd99b3777234d2df1c1d4e5?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 2,
      type: "track",
      uri: "spotify:track:0VRJVOMvyR4gubVIoGkS5C",
      is_local: false,
    },
    {
      album: {
        album_type: "SINGLE",
        total_tracks: 3,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/2LUo6oFDSN7qzgRd3CxBlg",
        },
        href: "https://api.spotify.com/v1/albums/2LUo6oFDSN7qzgRd3CxBlg",
        id: "2LUo6oFDSN7qzgRd3CxBlg",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b2732c7b27ac7db0219c381ab731",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e022c7b27ac7db0219c381ab731",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d000048512c7b27ac7db0219c381ab731",
            height: 64,
            width: 64,
          },
        ],
        name: "heavenly light (work tape)",
        release_date: "2021-03-26",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:2LUo6oFDSN7qzgRd3CxBlg",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/26DytDdxKgr9N0tdrBSLs2",
            },
            href: "https://api.spotify.com/v1/artists/26DytDdxKgr9N0tdrBSLs2",
            id: "26DytDdxKgr9N0tdrBSLs2",
            name: "Wilder Woods",
            type: "artist",
            uri: "spotify:artist:26DytDdxKgr9N0tdrBSLs2",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/26DytDdxKgr9N0tdrBSLs2",
          },
          href: "https://api.spotify.com/v1/artists/26DytDdxKgr9N0tdrBSLs2",
          id: "26DytDdxKgr9N0tdrBSLs2",
          name: "Wilder Woods",
          type: "artist",
          uri: "spotify:artist:26DytDdxKgr9N0tdrBSLs2",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 191724,
      explicit: false,
      external_ids: {
        isrc: "GBKPL2145288",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/2trTIGZiPMmKmz5MPWlgdG",
      },
      href: "https://api.spotify.com/v1/tracks/2trTIGZiPMmKmz5MPWlgdG",
      id: "2trTIGZiPMmKmz5MPWlgdG",
      name: "heavenly light (work tape)",
      popularity: 45,
      preview_url:
        "https://p.scdn.co/mp3-preview/4da559895d0b5d6ec09705bdc8132132cbd08735?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 1,
      type: "track",
      uri: "spotify:track:2trTIGZiPMmKmz5MPWlgdG",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 10,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/35rWm0QieUmTizxsC7dXnG",
        },
        href: "https://api.spotify.com/v1/albums/35rWm0QieUmTizxsC7dXnG",
        id: "35rWm0QieUmTizxsC7dXnG",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b27318576a9f01da7191b2455955",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e0218576a9f01da7191b2455955",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d0000485118576a9f01da7191b2455955",
            height: 64,
            width: 64,
          },
        ],
        name: "Wilder Woods",
        release_date: "2019-08-09",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:35rWm0QieUmTizxsC7dXnG",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/26DytDdxKgr9N0tdrBSLs2",
            },
            href: "https://api.spotify.com/v1/artists/26DytDdxKgr9N0tdrBSLs2",
            id: "26DytDdxKgr9N0tdrBSLs2",
            name: "Wilder Woods",
            type: "artist",
            uri: "spotify:artist:26DytDdxKgr9N0tdrBSLs2",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/26DytDdxKgr9N0tdrBSLs2",
          },
          href: "https://api.spotify.com/v1/artists/26DytDdxKgr9N0tdrBSLs2",
          id: "26DytDdxKgr9N0tdrBSLs2",
          name: "Wilder Woods",
          type: "artist",
          uri: "spotify:artist:26DytDdxKgr9N0tdrBSLs2",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 196451,
      explicit: false,
      external_ids: {
        isrc: "USAT21902704",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/0tsUIJakq2RksuC1YixAHX",
      },
      href: "https://api.spotify.com/v1/tracks/0tsUIJakq2RksuC1YixAHX",
      id: "0tsUIJakq2RksuC1YixAHX",
      name: "Supply & Demand",
      popularity: 50,
      preview_url:
        "https://p.scdn.co/mp3-preview/f312ad4ff3ce17989d7643fca0772d261f6cc2ea?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 4,
      type: "track",
      uri: "spotify:track:0tsUIJakq2RksuC1YixAHX",
      is_local: false,
    },
    {
      album: {
        album_type: "ALBUM",
        total_tracks: 11,
        available_markets: [
          "AR",
          "AU",
          "AT",
          "BE",
          "BO",
          "BR",
          "BG",
          "CA",
          "CL",
          "CO",
          "CR",
          "CY",
          "CZ",
          "DK",
          "DO",
          "DE",
          "EC",
          "EE",
          "SV",
          "FI",
          "FR",
          "GR",
          "GT",
          "HN",
          "HK",
          "HU",
          "IS",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MY",
          "MT",
          "MX",
          "NL",
          "NZ",
          "NI",
          "NO",
          "PA",
          "PY",
          "PE",
          "PH",
          "PL",
          "PT",
          "SG",
          "SK",
          "ES",
          "SE",
          "CH",
          "TW",
          "TR",
          "UY",
          "US",
          "GB",
          "AD",
          "LI",
          "MC",
          "ID",
          "JP",
          "TH",
          "VN",
          "RO",
          "IL",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "BY",
          "KZ",
          "MD",
          "UA",
          "AL",
          "BA",
          "HR",
          "ME",
          "MK",
          "RS",
          "SI",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "AM",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GE",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "SM",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "KG",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "UZ",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "TJ",
          "VE",
          "ET",
          "XK",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/1FmWxK03MqG0REpDWHiRg4",
        },
        href: "https://api.spotify.com/v1/albums/1FmWxK03MqG0REpDWHiRg4",
        id: "1FmWxK03MqG0REpDWHiRg4",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b27379cfee3c465540b0bca7b5ed",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e0279cfee3c465540b0bca7b5ed",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d0000485179cfee3c465540b0bca7b5ed",
            height: 64,
            width: 64,
          },
        ],
        name: "Pioneer",
        release_date: "2023-09-15",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:1FmWxK03MqG0REpDWHiRg4",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/2gXmjQIxCO8lMnSncluZaU",
            },
            href: "https://api.spotify.com/v1/artists/2gXmjQIxCO8lMnSncluZaU",
            id: "2gXmjQIxCO8lMnSncluZaU",
            name: "Cory Asbury",
            type: "artist",
            uri: "spotify:artist:2gXmjQIxCO8lMnSncluZaU",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/2gXmjQIxCO8lMnSncluZaU",
          },
          href: "https://api.spotify.com/v1/artists/2gXmjQIxCO8lMnSncluZaU",
          id: "2gXmjQIxCO8lMnSncluZaU",
          name: "Cory Asbury",
          type: "artist",
          uri: "spotify:artist:2gXmjQIxCO8lMnSncluZaU",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "AT",
        "BE",
        "BO",
        "BR",
        "BG",
        "CA",
        "CL",
        "CO",
        "CR",
        "CY",
        "CZ",
        "DK",
        "DO",
        "DE",
        "EC",
        "EE",
        "SV",
        "FI",
        "FR",
        "GR",
        "GT",
        "HN",
        "HK",
        "HU",
        "IS",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MY",
        "MT",
        "MX",
        "NL",
        "NZ",
        "NI",
        "NO",
        "PA",
        "PY",
        "PE",
        "PH",
        "PL",
        "PT",
        "SG",
        "SK",
        "ES",
        "SE",
        "CH",
        "TW",
        "TR",
        "UY",
        "US",
        "GB",
        "AD",
        "LI",
        "MC",
        "ID",
        "JP",
        "TH",
        "VN",
        "RO",
        "IL",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "BY",
        "KZ",
        "MD",
        "UA",
        "AL",
        "BA",
        "HR",
        "ME",
        "MK",
        "RS",
        "SI",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "AM",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GE",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "SM",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "KG",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "UZ",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "TJ",
        "VE",
        "ET",
        "XK",
      ],
      disc_number: 1,
      duration_ms: 197314,
      explicit: false,
      external_ids: {
        isrc: "QMGVJ2300108",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/1579jSTHBZ1fm5YTV3ubAy",
      },
      href: "https://api.spotify.com/v1/tracks/1579jSTHBZ1fm5YTV3ubAy",
      id: "1579jSTHBZ1fm5YTV3ubAy",
      name: "Kind",
      popularity: 56,
      preview_url:
        "https://p.scdn.co/mp3-preview/f3c93f592b2a3260ce91165a8c7ec15213e04f7b?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 9,
      type: "track",
      uri: "spotify:track:1579jSTHBZ1fm5YTV3ubAy",
      is_local: false,
    },
    {
      album: {
        album_type: "SINGLE",
        total_tracks: 1,
        available_markets: [
          "AR",
          "AU",
          "BO",
          "BR",
          "CA",
          "CL",
          "CO",
          "CR",
          "DO",
          "EC",
          "SV",
          "GT",
          "HN",
          "HK",
          "MY",
          "MX",
          "NZ",
          "NI",
          "PA",
          "PY",
          "PE",
          "PH",
          "SG",
          "TW",
          "UY",
          "US",
          "ID",
          "JP",
          "TH",
          "VN",
          "ZA",
          "SA",
          "AE",
          "BH",
          "QA",
          "OM",
          "KW",
          "EG",
          "MA",
          "DZ",
          "TN",
          "LB",
          "JO",
          "PS",
          "IN",
          "KR",
          "BD",
          "PK",
          "LK",
          "GH",
          "KE",
          "NG",
          "TZ",
          "UG",
          "AG",
          "BS",
          "BB",
          "BZ",
          "BT",
          "BW",
          "BF",
          "CV",
          "CW",
          "DM",
          "FJ",
          "GM",
          "GD",
          "GW",
          "GY",
          "HT",
          "JM",
          "KI",
          "LS",
          "LR",
          "MW",
          "MV",
          "ML",
          "MH",
          "FM",
          "NA",
          "NR",
          "NE",
          "PW",
          "PG",
          "PR",
          "WS",
          "ST",
          "SN",
          "SC",
          "SL",
          "SB",
          "KN",
          "LC",
          "VC",
          "SR",
          "TL",
          "TO",
          "TT",
          "TV",
          "VU",
          "AZ",
          "BN",
          "BI",
          "KH",
          "CM",
          "TD",
          "KM",
          "GQ",
          "SZ",
          "GA",
          "GN",
          "LA",
          "MO",
          "MR",
          "MN",
          "NP",
          "RW",
          "TG",
          "ZW",
          "BJ",
          "MG",
          "MU",
          "MZ",
          "AO",
          "CI",
          "DJ",
          "ZM",
          "CD",
          "CG",
          "IQ",
          "LY",
          "VE",
          "ET",
        ],
        external_urls: {
          spotify: "https://open.spotify.com/album/2NG3PVvi3qkO5mEESVcVIO",
        },
        href: "https://api.spotify.com/v1/albums/2NG3PVvi3qkO5mEESVcVIO",
        id: "2NG3PVvi3qkO5mEESVcVIO",
        images: [
          {
            url: "https://i.scdn.co/image/ab67616d0000b27385885bd1164add25c60f768e",
            height: 640,
            width: 640,
          },
          {
            url: "https://i.scdn.co/image/ab67616d00001e0285885bd1164add25c60f768e",
            height: 300,
            width: 300,
          },
          {
            url: "https://i.scdn.co/image/ab67616d0000485185885bd1164add25c60f768e",
            height: 64,
            width: 64,
          },
        ],
        name: "Ocean Avenue",
        release_date: "2024-01-12",
        release_date_precision: "day",
        type: "album",
        uri: "spotify:album:2NG3PVvi3qkO5mEESVcVIO",
        artists: [
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/3zxKH0qp3nBCuPZCZT5Vaf",
            },
            href: "https://api.spotify.com/v1/artists/3zxKH0qp3nBCuPZCZT5Vaf",
            id: "3zxKH0qp3nBCuPZCZT5Vaf",
            name: "Yellowcard",
            type: "artist",
            uri: "spotify:artist:3zxKH0qp3nBCuPZCZT5Vaf",
          },
          {
            external_urls: {
              spotify: "https://open.spotify.com/artist/0VOR7Ie9xUSb45fzIIVJQ1",
            },
            href: "https://api.spotify.com/v1/artists/0VOR7Ie9xUSb45fzIIVJQ1",
            id: "0VOR7Ie9xUSb45fzIIVJQ1",
            name: "Hammock",
            type: "artist",
            uri: "spotify:artist:0VOR7Ie9xUSb45fzIIVJQ1",
          },
        ],
      },
      artists: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/3zxKH0qp3nBCuPZCZT5Vaf",
          },
          href: "https://api.spotify.com/v1/artists/3zxKH0qp3nBCuPZCZT5Vaf",
          id: "3zxKH0qp3nBCuPZCZT5Vaf",
          name: "Yellowcard",
          type: "artist",
          uri: "spotify:artist:3zxKH0qp3nBCuPZCZT5Vaf",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/0VOR7Ie9xUSb45fzIIVJQ1",
          },
          href: "https://api.spotify.com/v1/artists/0VOR7Ie9xUSb45fzIIVJQ1",
          id: "0VOR7Ie9xUSb45fzIIVJQ1",
          name: "Hammock",
          type: "artist",
          uri: "spotify:artist:0VOR7Ie9xUSb45fzIIVJQ1",
        },
      ],
      available_markets: [
        "AR",
        "AU",
        "BO",
        "BR",
        "CA",
        "CL",
        "CO",
        "CR",
        "DO",
        "EC",
        "SV",
        "GT",
        "HN",
        "HK",
        "MY",
        "MX",
        "NZ",
        "NI",
        "PA",
        "PY",
        "PE",
        "PH",
        "SG",
        "TW",
        "UY",
        "US",
        "ID",
        "JP",
        "TH",
        "VN",
        "ZA",
        "SA",
        "AE",
        "BH",
        "QA",
        "OM",
        "KW",
        "EG",
        "MA",
        "DZ",
        "TN",
        "LB",
        "JO",
        "PS",
        "IN",
        "KR",
        "BD",
        "PK",
        "LK",
        "GH",
        "KE",
        "NG",
        "TZ",
        "UG",
        "AG",
        "BS",
        "BB",
        "BZ",
        "BT",
        "BW",
        "BF",
        "CV",
        "CW",
        "DM",
        "FJ",
        "GM",
        "GD",
        "GW",
        "GY",
        "HT",
        "JM",
        "KI",
        "LS",
        "LR",
        "MW",
        "MV",
        "ML",
        "MH",
        "FM",
        "NA",
        "NR",
        "NE",
        "PW",
        "PG",
        "WS",
        "ST",
        "SN",
        "SC",
        "SL",
        "SB",
        "KN",
        "LC",
        "VC",
        "SR",
        "TL",
        "TO",
        "TT",
        "TV",
        "VU",
        "AZ",
        "BN",
        "BI",
        "KH",
        "CM",
        "TD",
        "KM",
        "GQ",
        "SZ",
        "GA",
        "GN",
        "LA",
        "MO",
        "MR",
        "MN",
        "NP",
        "RW",
        "TG",
        "ZW",
        "BJ",
        "MG",
        "MU",
        "MZ",
        "AO",
        "CI",
        "DJ",
        "ZM",
        "CD",
        "CG",
        "IQ",
        "LY",
        "VE",
        "ET",
      ],
      disc_number: 1,
      duration_ms: 308400,
      explicit: false,
      external_ids: {
        isrc: "US3X52351503",
      },
      external_urls: {
        spotify: "https://open.spotify.com/track/40CYN0vEpj7UPHPUvQDoyJ",
      },
      href: "https://api.spotify.com/v1/tracks/40CYN0vEpj7UPHPUvQDoyJ",
      id: "40CYN0vEpj7UPHPUvQDoyJ",
      name: "Ocean Avenue",
      popularity: 38,
      preview_url:
        "https://p.scdn.co/mp3-preview/28c5637bb7b2270d69da0029ec68fbea8fe653fd?cid=f6bdffdf91294cc59db4efb2bf4389d5",
      track_number: 1,
      type: "track",
      uri: "spotify:track:40CYN0vEpj7UPHPUvQDoyJ",
      is_local: false,
    },
  ],
};
